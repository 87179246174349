import React, { Component } from 'react';
import './tailwind.generated.css';
import Tuple from './img/logos/tuple-logo.svg'

class Logos extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="bg-white">
                <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
                        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                        <img className="h-12" src={Tuple} alt="Tuple" />
                        </div>
                        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                        <img className="h-12" src={Tuple} alt="Mirage" />
                        </div>
                        <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                        <img className="h-12" src={Tuple} alt="StaticKit" />
                        </div>
                        <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
                        <img className="h-12" src={Tuple} alt="Transistor" />
                        </div>
                        <div className="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
                        <img className="h-12" src={Tuple} alt="Workcation" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Logos;
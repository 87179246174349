import React, { Component } from 'react';
import './tailwind.generated.css';

class CTA extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div class="bg-white">
            <div class="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
              <h2 class="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                Ready to dive in?
                <br />
                Schedule a demo now.
              </h2>
              <div class="mt-8 flex justify-center">
                <div class="inline-flex rounded-md shadow">
                  <a href="https://meetings.hubspot.com/victor178/devflight-demo" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                    Get a demo
                  </a>
                </div>
                <div class="ml-3 inline-flex">
                  <a href="https://meetings.hubspot.com/victor178/devflight-demo" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:text-indigo-600 hover:bg-indigo-50 focus:outline-none focus:shadow-outline focus:border-indigo-300 transition duration-150 ease-in-out">
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
            // <div className="bg-gray-800">
            //     <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center">
            //         <div className="lg:w-0 lg:flex-1">
            //         <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-white sm:text-4xl sm:leading-10">
            //             Get a demo.
            //         </h2>
            //         <p className="mt-3 max-w-3xl text-lg leading-6 text-gray-300">
            //             Connect with a DevFlight team member to get your questions answered.
            //         </p>
            //         </div>
            //         <div className="mt-8 lg:mt-0 lg:ml-8">
            //             <form name="contact-footer" method="POST" className="sm:flex">
            //                 <input type="hidden" name="form-name" value="contact" />
            //                 <input aria-label="Email address" required className="appearance-none w-full px-5 py-3 border border-transparent text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 transition duration-150 ease-in-out sm:max-w-xs" placeholder="Enter your email" type="email" name="email" />
            //                 <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
            //                     <button className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-400 focus:outline-none focus:bg-indigo-400 transition duration-150 ease-in-out">
            //                         Get a demo
            //                     </button>
            //                 </div>
            //             </form>
            //             {/* <p className="mt-3 text-sm leading-5 text-gray-300">
            //                 We care about the protection of your data. Read our
            //                 <a href="#" className="text-white font-medium underline">
            //                 Privacy Policy.
            //                 </a>
            //             </p> */}
            //         </div>
            //     </div>
            // </div>
        );
    }
}

export default CTA;
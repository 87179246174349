import React, { Component } from 'react';


class Resources extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
                <div className="relative max-w-lg mx-auto lg:max-w-7xl">
                    <div>
                        <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
                            Resources
                    </h2>
                        <p className="mt-3 text-xl leading-7 text-gray-500 sm:mt-4">
                            Useful content for you to learn about what power you have as a renter.
                    </p>
                    </div>
                    <div className="mt-12 grid gap-16 border-t-2 border-gray-100 pt-12 lg:grid-cols-3 lg:col-gap-5 lg:row-gap-12">
                        <div>
                            <div>
                                <a href="#" className="inline-block">
                                    <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-indigo-100 text-indigo-800">
                                        Article
                            </span>
                                </a>
                            </div>
                            <a target="_blank" href="https://www.nypl.org/blog/2012/07/09/beyond-311-how-to-direct-complaints" className="block">
                                <h3 className="mt-4 text-xl leading-7 font-semibold text-gray-900">
                                    Beyond 311: How to Direct Complaints to NYC, State or Federal Agencies
                        </h3>
                                <p className="mt-3 text-base leading-6 text-gray-500">
                                    Landlord-tenant disputes are a fact that a large percentage of New York City
                                    residents must confront at one time or another if they live in this city.
                        </p>
                            </a>
                            <div className="mt-6 flex items-center">
                                {/* <div className="flex-shrink-0">
                            <a href="">
                            <img className="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
                            </a>
                        </div>
                        <div className="ml-3">
                            <p className="text-sm leading-5 font-medium text-gray-900">
                            <a href="#">
                                Matthew J. Boylan
                            </a>
                            </p>
                            <div className="flex text-sm leading-5 text-gray-500">
                            <time datetime="2020-03-16">
                                Mar 16, 2020
                            </time>
                            <span className="mx-1">
                                &middot;
                            </span>
                            <span>
                                6 min read
                            </span>
                            </div>
                        </div> */}
                            </div>
                        </div>
                        <div>
                            <div>
                                <a href="#" className="inline-block">
                                    <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-indigo-100 text-indigo-800">
                                        Article
                            </span>
                                </a>
                            </div>
                            <a target="_blank" href="https://flip.lease/learn/requesting-repairs/withholding-rent-in-new-york" className="block">
                                <h3 className="mt-4 text-xl leading-7 font-semibold text-gray-900">
                                    Can I Withhold Rent for Repairs in New York?
                        </h3>
                                <p className="mt-3 text-base leading-6 text-gray-500">
                                    You can legally withhold rent in New York if anything is seriously wrong with your apartment, like a lack of heat or a roach infestation.
                        </p>
                            </a>
                            <div className="mt-6 flex items-center">
                                {/* <div className="flex-shrink-0">
                            <a href="#">
                            <img className="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
                            </a>
                        </div>
                        <div className="ml-3">
                            <p className="text-sm leading-5 font-medium text-gray-900">
                            <a href="#">
                                Flip
                            </a>
                            </p>
                            <div className="flex text-sm leading-5 text-gray-500">
                            <time datetime="2020-03-15">
                                Mar 15, 2020
                            </time>
                            <span className="mx-1">
                                &middot;
                            </span>
                            <span>
                                4 min read
                            </span>
                            </div>
                        </div> */}
                            </div>
                        </div>
                        <div>
                            <div>
                                <a href="#" className="inline-block">
                                    <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-indigo-100 text-indigo-800">
                                        Article
                            </span>
                                </a>
                            </div>
                            <a target="_blank" href="https://nycourts.gov/courts/nyc/housing/pdfs/warrantyofhabitability.pdf" className="block">
                                <h3 className="mt-4 text-xl leading-7 font-semibold text-gray-900">
                                    Landlord/Tenant Fact Sheet
                        </h3>
                                <p className="mt-3 text-base leading-6 text-gray-500">
                                    The warranty of habitability makes the landlord or owner responsible
                                    for keeping your apartment and the building safe and livable at all times.
                        </p>
                            </a>
                            <div className="mt-6 flex items-center">
                                {/* <div className="flex-shrink-0">
                            <a href="#">
                            <img className="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
                            </a>
                        </div>
                        <div className="ml-3">
                            <p className="text-sm leading-5 font-medium text-gray-900">
                            <a href="#">
                                Easter Collins
                            </a>
                            </p>
                            <div className="flex text-sm leading-5 text-gray-500">
                            <time datetime="2020-03-10">
                                Mar 10, 2020
                            </time>
                            <span className="mx-1">
                                &middot;
                            </span>
                            <span>
                                11 min read
                            </span>
                            </div>
                        </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Resources;